import React from 'react';
import HeroBanner from '../../shared/Hero/HeroBanner';


const Hero = () => {
  return (
    <>
      <div id='HeroSectionScroll'>
        <HeroBanner/>
      </div>
      
    </>
  )
}

export default Hero
