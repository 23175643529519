import FirstImg from '../assets/img/carousel/carousel1.jpg';
import SecondImg from '../assets/img/carousel/carousel2.jpg';
import ThirtImg from '../assets/img/carousel/carousel3.jpg';


export const ImgCarousel = [
    {id: 1,
    img: FirstImg
},
{   id:2,
    img:SecondImg
},
{   
    id:3,
    img:ThirtImg
}
]