export const company = {
    statusId: 1,
    companyId: 1,
    codigoUsuarioMaestro: 0,
  };
  
  export const companyForm = {
    id: 1,
  };
  
